/*
 * @Author: dingguowei
 * @Date: 2023-05-18 11:26:03
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-05-29 16:12:28
 */
import request from '@/plugins/axios'
const base = '/'

export function getJdOrder(params) {
  return request({
    url:  base + 'api/jd/orderId/mx/get',
    method: 'get',
    params
  })
}

export function getLgJdOrder(params) {
  return request({
    url:  base + 'api/jd/orderId/lg/get',
    method: 'get',
    params
  })
}